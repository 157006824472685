body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
}

.app_root {
    display: flex;
    flex: 1;
    flex-direction: column;
}

canvas{
    margin: 0 auto;
}